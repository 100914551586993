//
// Matrixtable
//
/* Matrixtable */

//
// Matrixtable
//
/* Matrixtable */

.matrica-scroll {
  max-width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  padding: 10px;
}

.matrica-table {
  width: max-content; /* Ensures table expands while keeping scroll */
  border-collapse: collapse;
  table-layout: auto; /* Ensures dynamic column sizing */
}

.matrica-table th, .matrica-table td {
  border: 1px solid #dee2e6;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}
.matrica-table th:first-child,
.matrica-table td:first-child {
  width: 100px!important;
}
/* Freeze First Column (Employee Column) */
.employee-column {
  position: sticky;
  left: 0;
  z-index: 2; /* Keeps it above other table content */
  background-color: #f8f9fa; /* Ensures readability */
  width: 350px; /* Adjust as needed */
  text-align: left;
  font-weight: bold;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Adds slight shadow to separate it */
}

/* Freeze Employee Names (First Column Cells) */
.employee-name {
  position: sticky;
  left: 0;
  background-color: #fff; /* Prevents transparency issues */
  z-index: 1;
  font-weight: bold;
  text-align: left;
  width: 350px;
}

/* Rotated header: Dynamic width to fit text without overlapping */
.rotated-header {
  min-width: 50px; /* Prevents too much compression */
  max-width: 120px; /* Prevents excessive width */
  height: auto; /* Allow height to adjust */
  position: relative;
  text-align: center;
  vertical-align: bottom;
  overflow: hidden;
  white-space: nowrap; /* Prevents text from wrapping */
}

/* Ensure text stays in its column and does not overlap */
.rotated-header div {
  writing-mode: vertical-rl; /* Rotates text 90 degrees upwards */
  transform: rotate(180deg); /* Ensures the text flows from bottom to top */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1px;
  height: 50%;
  width: 100%;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;
}

/* Small icons */
.small-icon {
  font-size: 10px;
}

/* Rotated Logo */
.rotated-logo {
  transform: rotate(-90deg); /* Rotate 90 degrees counterclockwise */
  width: 300px;
  display: block;
  margin: 0 auto;
}

/* Keep Table Scrollable */
.matrica-scroll {
  max-width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents text from wrapping */
  padding: 10px;
}

.matrica-table th, .matrica-table td {
  border: 1px solid #dee2e6;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}
